import { merge } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { default as BaseTagTheme } from 'base/components/Tag/theme';

const Tag = tw.theme({
  extend: BaseTagTheme,
  base: 'text-preview-sm',
  variants: merge(BaseTagTheme.variants, {
    colors: {
      default: `bg-light-sand-500 hover:bg-light-sand-900 active:bg-light-sand-900 focus:bg-light-sand-500 border-transparent text-black ring-transparent focus:border-gray-700`,
      alternative: 'text-gray-800',
    },
    size: {
      small: `px-3 py-1`,
      medium: `px-3 py-1.75`,
      large: `px-6 py-2.75`,
      video: 'text-preview-sm uppercase',
    },
    variant: {
      default: 'rounded-none border-2',
    },
  }),
});

export default Tag;
