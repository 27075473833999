import { tw } from '@/utils/tw';
import { default as BaseCoverBox } from 'base/components/CoverBox/theme';

const Prose = tw.theme({
  extend: BaseCoverBox.Prose,
  base: 'prose-coverbox',
});

const CoverBox = tw.theme({
  extend: BaseCoverBox,
  slots: {
    base: 'bg-white wings-white',
    headline: 'text-gabor-lg sm:text-gabor-lg uppercase text-black',
    toggle: 'text-preview-sm bg-white !font-medium',
    group: 'text-preview-sm',
  },
});

export default Object.assign(CoverBox, { Prose });
