import { tw } from '@/utils/tw';
import BaseBreadcrumbsTheme from 'base/components/Breadcrumbs/theme';

const Breadcrumbs = tw.theme({
  extend: BaseBreadcrumbsTheme,
  slots: {
    step: `text-preview-sm uppercase text-gray-800`,
    separator: `text-preview-sm text-gray-800`,
  },
});

export default Breadcrumbs;
