import { mergeProps } from '@/utils/merge';
import { StandaloneMarkup } from 'base/components/Markup';
import useUserAuth from 'lib/hooks/useUserAuth';
import { useUserName } from 'lib/hooks/useUserName';

export const ElleStandaloneMarkup: typeof StandaloneMarkup = ({ markup, ...props }) => {
  const { isUserLoggedIn } = useUserAuth();
  const { firstName } = useUserName('Användare');

  const showMemberMarkup = isUserLoggedIn && markup?.includes('id="elle-member-welcome"');

  return (
    <StandaloneMarkup
      {...mergeProps(
        {
          markup: showMemberMarkup
            ? markup?.replace(/<img[^>]*?>/g, `Hej ${firstName} och välkommen till ELLE Member`)
            : markup,
        },
        props,
      )}
    />
  );
};
